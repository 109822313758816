<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="3" class="pa-0">
                Reference Selector
                <v-checkbox v-model="selections" v-for="reference in references" :key="'reference_checkbox_'+reference.id" :label="'['+reference.id+'] '+reference.description" :value="reference.id"/>
            </v-col>
            <v-col cols="9" class="pa-0">
                Reference Order
                    <v-list class="pa-0">
                        <draggable v-model="selections">
                        <v-list-item v-for="(selection, selectionIndex) in selections" :key="'selection_'+selectionIndex">
                            <v-list-item-icon>
                                <v-icon>mdi-drag-horizontal</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="reference">
                                <span v-html="'['+referenceMap[selection].id+'] - '+referenceMap[selection].content[language]"/>
                            </v-list-item-content>
                        </v-list-item>
                        </draggable>
                    </v-list>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    props: {
        references: {
            type: Array,
            required: true
        }
    },
    components: {
        draggable
    },
    created: function(){
        this.selections = this.$attrs.value
    },
    data: function(){
        return {
            selections: []
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        referenceMap: function(){
            let references = this.references
            let output = {}
            for(let i=0; i<references.length; i++){
                let reference = references[i]
                output[reference.id] = reference
            }

            return output
        }
    },
    watch: {
        selections: function(){
            this.$emit('input',this.selections)
        }
    }
}
</script>

<style scoped>
.reference{
    font-size: 0.8em;
}
</style>