<template>
<div>
    <v-select v-model="displayLogic.requirement" :items="logicRequirementItems" label="Requirement Type"/>
    <v-text-field v-if="displayLogic.requirement=='xTrue'" type="number" v-model="displayLogic.x" label="X Number of true"/>

    <v-container>
        <v-row v-for="(logic, logicIndex) in logics" :key="'logic_selector_'+logicIndex">
            <v-col>
                <v-checkbox dense v-model="checkboxes" :label="logic.description" :value="logic.id"/>  
            </v-col>
            <v-col class="pa-0">
                <v-switch :true-value="true" :false-value="false" v-if="checkboxes.includes(logic.id)" @change="setRules()" v-model="results[logic.id]" label="Logic should be true"/>
            </v-col>
        </v-row>
    </v-container>
    
</div>
</template>

<script>
export default {
    name: "LogicSelector",
    props: {
        logicTemplate: {
            type: Object,
            required: true
        },
        logics: {
            type: Array,
            required: true
        }
    },
    created: function(){
        this.displayLogic = this.$attrs.value ? this.$attrs.value : {rules: []}
        for(let i=0; i<this.displayLogic.rules.length; i++){
            let rule = this.displayLogic.rules[i]
            this.checkboxes.push(rule.rule_id)
            this.results[rule.rule_id] = rule.result
        }
        this.ready = true
    },
    data: function(){
        return {
            ready: false,
            displayLogic: {
                requirement: false,
                rules: []
            },
            checkboxes: [],
            results: {}
        }
    },
    methods: {
        setRules: function(){
            this.displayLogic.rules = []
            for(let c=0; c<this.checkboxes.length; c++){
                let rule_id = this.checkboxes[c]
                let result = this.results[rule_id]
                let output = {
                    rule_id, result
                }
                this.displayLogic.rules.push(output)
            }
            this.$emit('input',this.displayLogic)
        },
        setResults: function(){
            let checkboxes = this.checkboxes
            let results = this.results
            for(let c=0; c<checkboxes.length; c++){
                let rule_id = checkboxes[c]
                if(results[rule_id]==undefined){
                    results[rule_id] = true
                }
            }          
        }
    },
    computed: {
        logicRequirementItems: function(){
            let output = [
                {
                    text: 'allTrue',
                    value: 'allTrue'
                },
                {
                    text: 'anyTrue',
                    value: 'anyTrue'
                },
                {
                    text: 'xTrue',
                    value: 'xTrue'
                }
            ]

            return output
        }        
    },
    watch: {
        checkboxes: function(){
            this.setResults()
            this.setRules()
        }
    }
}
</script>

<style>

</style>