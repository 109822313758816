<template>
<div :style="{'max-width':'1400px','margin':'0 auto'}">
    <fieldset>
        <legend>TO DO</legend>
        <ul>
            <li>Logics - false with unanswered states</li>
            <li>Rating</li>
            <li>Ranking</li>
            <li>Numbers:  allow decimal and places</li>
            <li>Checkbox requirements linked to subtitle</li>
            <li>Percentage - validation</li>
        </ul>
    </fieldset>
    <v-btn @click="initialize">Initialize</v-btn>
    <v-btn v-if="undo!=null && !ui.preview" color="primary" @click="restore">
        <v-icon>mdi-file-undo-outline</v-icon> Undo
    </v-btn>
    <v-container>
        <v-row>
            <v-col>                
                <v-container>
                    <v-row>
                        <v-col>
                            <v-select v-model="editing.questionSetID" :items="questionSetItems" label="Select Question Set"/>
                        </v-col>

                        <v-col>
                            <v-btn icon color="primary" @click="ui.newQuestionSet = true"><v-icon>mdi-new-box</v-icon></v-btn>
                        </v-col>
                        <v-col>
                            <v-btn v-if="editing.questionSetID!=null" icon color="success" @click="questionSet.load(editing.questionSetID)"><v-icon>mdi-briefcase-download</v-icon>LOAD</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn v-if="editing.questionSetID!=null" icon color="success" @click="ui.copyQuestionSet=true"><v-icon>mdi-content-copy</v-icon>COPY</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn v-if="editing.questionSetID!=null" icon color="error" @click="questionSet.remove(editing.questionSetID)"><v-icon>mdi-delete</v-icon>Delete</v-btn>
                        </v-col>
                    </v-row>
                </v-container>                                            
            </v-col>
        </v-row>

        <template v-if="selection.questionSet">
            <v-row>
                <v-col>
                    <v-navigation-drawer
                        v-if="editing.questionSetID && !ui.preview"
                        v-model="ui.questionNav"
                        fixed
                        width="200"
                        >
                        <v-list>
                            <v-list-item>
                                Question Navigation
                            </v-list-item>

                            <v-list-item>
                                <v-btn fab x-small color="success" style="margin: 15px;" @click="newQuestion"><v-icon>mdi-plus</v-icon></v-btn>
                                <v-btn fab x-small color="warning" style="margin: 15px;" @click="init"><v-icon>mdi-reload</v-icon></v-btn>                            
                            </v-list-item>
                        </v-list>
                        <v-divider/>
                        
                        <v-list>
                            <!-- <draggable v-model="questions">
                                <v-list-item v-for="(question, questionIndex) in questions" :key="'editor_question_nav_'+question.id"> -->
                            <draggable v-model="selection.questionSet.questions">
                                <v-list-item v-for="(question, questionIndex) in selection.questionSet.questions" :key="'editor_question_nav_'+question.id">                                    
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                    <v-btn @click="survey().scrollToQuestion(question)" @mouseenter="survey().highlightQuestionON(question)"  @mouseleave="survey().highlightQuestionOFF(question)" :class="[{'hasLogic':question.canHide || question.canDisable},{'hidden':!question.display}]">
                                        #{{questionIndex+1}} - id:{{question.id}}
                                    </v-btn>
                                    <v-btn x-small color="primary lighten-1" @click="questionSet.editQuestion(questionIndex)" icon>
                                        <v-icon >mdi-pencil</v-icon>
                                    </v-btn>
                                </v-list-item>
                            </draggable>
                        </v-list>

                    </v-navigation-drawer>   
                    <v-switch v-model="ui.preview" :true-value="true" :false-value="false" label="Preview Survey"/>
                    <!-- <v-switch v-model="ui.debug" :true-value="true" :false-value="false" label="Debug"/> -->
                </v-col>
                <v-col align="right">
                    <v-btn icon color="error" v-if="!ui.preview" @click="questionSet.save"><v-icon>mdi-content-save</v-icon>SAVE</v-btn>
                </v-col>
                <v-col>
                    <v-navigation-drawer
                        v-if="editing.questionSetID && !ui.preview"
                        v-model="ui.questionNav"
                        fixed
                        :right="true"
                        >
                        <v-list>
                            <v-list-item>
                                KT Navigation
                            </v-list-item>

                            <v-list-item>
                                <v-btn fab x-small color="success" style="margin: 15px;" @click="questionSet.newPrompt"><v-icon>mdi-plus</v-icon></v-btn>                     
                            </v-list-item>
                        </v-list>

                        <v-divider/>
                        
                        <draggable v-model="prompts">
                            <div v-for="(prompt, promptIndex) in prompts" :key="'editor_prompt_nav_'+prompt.id" class="nav-card-prompt" @click="questionSet.editPrompt(promptIndex)">
                                <Prompt :prompt="prompt" :references="references" :assets="assets" :debug="debug"/>
                            </div>
                        </draggable>

                    </v-navigation-drawer>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-expansion-panels v-model="ui.panels" multiple>
                        <v-expansion-panel :key="0">
                            <v-expansion-panel-header>
                                Logics
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="border: 1px solid grey; margin: 15px;padding: 12px;">
                                    <v-container width="100%" class="debug-logic-summary">

                                        <v-row class="header">
                                            <v-col cols="1">
                                                <v-btn icon v-if="!ui.preview" @click="addLogic">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1">ID</v-col>
                                            <v-col cols="3">Description</v-col>
                                            <v-col cols="1">Requirement</v-col>
                                            <v-col cols="4">Rules</v-col>
                                            <v-col cols="1">Result</v-col>
                                            <v-col cols="1"></v-col>
                                        </v-row>


                                        <draggable v-model="logics">
                                            <v-row v-for="(logic, logicIndex) in logics" :key="'logic_table_'+logicIndex" class="striped">
                                                <v-col cols="1">
                                                    <v-btn icon small v-if="!ui.preview" @click="editLogic(logicIndex)">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1">
                                                    {{logic.id}}
                                                </v-col>
                                                <v-col cols="3">
                                                    {{logic.description}}
                                                </v-col>
                                                <v-col cols="1">
                                                    {{logic.requirement}}
                                                </v-col>
                                                <v-col cols="4" class="pa-0">
                                                    <table v-if="logic.report" width="100%" style="font-size: 9pt;">
                                                        <thead>
                                                            <tr>
                                                                <th>Description</th>
                                                                <th width="10%;">Input</th>
                                                                <th width="10%;">Result</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr v-for="(report, r) in logic.report.rules" :key="logic.id+'_'+r">
                                                                <td>
                                                                    <span v-html="report.helper"/>
                                                                </td>
                                                                <td style="text-align: center;">
                                                                    <template v-if="report.input.answer!=null">
                                                                        <template v-if="typeof report.input.answer=='object'">
                                                                            {{report.rule.source.input.choice_id ? report.input.answer[report.rule.source.input.choice_id] : report.input.answer}}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{report.input.answer}}
                                                                        </template>
                                                                    </template>
                                                                </td>
                                                                <td style="text-align: center;" :class="[{'true':report.result}, {'false':!report.result}]">
                                                                    {{report.result}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </v-col>
                                                <v-col cols="1" :class="[{'true':logic.result}, {'false':!logic.result}]">
                                                    {{logic.result}}
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn icon small v-if="!ui.preview" @click="deleteItem(logicIndex, logics, 'logic')">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </draggable>

                                    </v-container>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel :key="1">
                            <v-expansion-panel-header>
                                References
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="border: 1px solid grey; margin: 15px;padding: 12px;">
                                    <v-container>
                                        <v-row class="header">
                                            <v-col cols="1">
                                                <v-btn icon @click="addReference">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1">ID</v-col>
                                            <v-col cols="3">Description</v-col>
                                            <v-col v-for="lang in config.languages" :key="'reference_content_header_'+lang">{{lang}}</v-col>
                                        </v-row>

                                        <draggable v-model="references">
                                            <v-row v-for="(reference, referenceIndex) in references" :key="'reference_table_'+referenceIndex">
                                                <v-col cols="1">
                                                    <v-btn icon small @click="editReference(referenceIndex)">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1">{{references[referenceIndex].id}}</v-col>
                                                <v-col cols="2">{{references[referenceIndex].description}}</v-col>
                                                <v-col v-for="lang in config.languages" :key="'reference_'+referenceIndex+'_'+lang">
                                                    <span class="reference" v-html="references[referenceIndex].content[lang]"/>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn icon small @click="deleteItem(referenceIndex, references, 'reference')">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </draggable>
                                    </v-container>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel :key="2">
                            <v-expansion-panel-header>
                                Assets
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="border: 1px solid grey; margin: 15px;padding: 12px;">
                                    <v-container>
                                        <v-row class="header">
                                            <v-col cols="1">
                                                <v-btn icon @click="addAsset">
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="1">ID</v-col>
                                            <v-col cols="1">Type</v-col>
                                            <v-col cols="3">Description</v-col>
                                            <v-col v-for="lang in config.languages" :key="'reference_content_header_'+lang">{{lang}}</v-col>
                                        </v-row>

                                        <draggable v-model="assets">
                                            <v-row v-for="(asset, assetIndex) in assets" :key="'reference_table_'+assetIndex">
                                                <v-col cols="1">
                                                    <v-btn icon small @click="editAsset(assetIndex)">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1">{{assets[assetIndex].id}}</v-col>
                                                <v-col cols="1">{{assets[assetIndex].type}}</v-col>
                                                <v-col cols="2">{{assets[assetIndex].description}}</v-col>
                                                <v-col v-for="lang in config.languages" :key="'reference_'+assetIndex+'_'+lang">
                                                    <img class="asset" v-if="assets[assetIndex].content[lang]" :src="assets[assetIndex].content[lang]" style="width: 200px;"/>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-btn icon small @click="deleteItem(imageIndex, assets, 'asset')">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </draggable>
                                    </v-container>

                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                    </v-expansion-panels>

                    <Survey v-if="ui.preview" :debug="debug" :questionSetID="editing.questionSetID" :questionSet="{questions,logics,prompts,references, assets}"/>

                    <v-container v-else>
                        <v-row>
                            <v-container>
                                <v-row v-for="(question, qIndex) in questions" :key="'question_row_'+qIndex">
                                    <v-col>
                                        <div class="question-container">
                                            <div v-if="!ui.preview">
                                                <v-btn icon style="position: absolute; top: 0px; right: -40px;">
                                                    <v-icon color="lighten-1" @click="questionSet.editQuestion(qIndex)">mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn icon style="position: absolute; top: 45px; right: -40px;">
                                                    <v-icon color="red lighten-1" @click="deleteItem(qIndex, questions, 'question')">mdi-delete</v-icon>
                                                </v-btn>
                                            </div>

                                            <template v-for="(promptRef, promptIndex) in question.prompts">
                                                <div v-if="promptMap[promptRef.prompt_id] && promptRef.position=='top'" :key="'question_'+question.id+'_prompt_top_'+promptRef.prompt_id" style="position: relative;">
                                                    <Prompt :debug="debug" :assets="assets" :prompt="promptMap[promptRef.prompt_id]" :references="references" class="prompt" :class="[{'hidden':!promptMap[promptRef.prompt_id].display}]" v-show="promptMap[promptRef.prompt_id].display || !ui.preview"/>
                                                    <v-btn style="position: absolute; bottom:5px; right:0px;" icon x-small @click="questionSet.editPrompt(promptIndex)">
                                                        <v-icon >mdi-pencil</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <Question v-model="questions[qIndex].form" :triggers="ui.triggers[question.id]" :debug="debug" :question="question" @questionUpdate="displayHandler" v-show="question.display || !ui.preview"/>
                                            <template v-for="(promptRef, promptIndex) in question.prompts">
                                                <div v-if="promptMap[promptRef.prompt_id] && promptRef.position=='bottom'" :key="'question_'+question.id+'_prompt_bottom_'+promptRef.prompt_id" style="position: relative;">
                                                    <Prompt :debug="debug" :assets="assets" :prompt="promptMap[promptRef.prompt_id]" :references="references" class="prompt" :class="[{'hidden':!promptMap[promptRef.prompt_id].display}]" v-show="promptMap[promptRef.prompt_id].display || !ui.preview"/>
                                                    <v-btn style="position: absolute; bottom:5px; right: 5px;" icon x-small @click="questionSet.editPrompt(promptIndex)">
                                                        <v-icon >mdi-pencil</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>

                                            <template v-if="logics.length>0 && question.canHide && debug">              
                                                <v-container>
                                                    <v-row>
                                                        <v-col>
                                                            Requirement: {{question.displayLogic.requirement}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>Description</v-col>
                                                        <v-col>Rule</v-col>
                                                        <v-col>Input</v-col>
                                                    </v-row>

                                                    <template v-for="(displayRule, displayRuleIndex) in question.displayLogic.rules">
                                                        <template v-if="logicMap[displayRule.rule_id] && logicMap[displayRule.rule_id].report">
                                                            <template v-for="(rule, r) in logicMap[displayRule.rule_id].report.rules">
                                                            <v-row :key="'qid_'+question.id+'_rule_'+displayRuleIndex+'_'+r">
                                                                <v-col class="pa-0">{{logicMap[displayRule.rule_id].description}}</v-col>
                                                                <v-col class="pa-0"><span v-html="rule.helper"/></v-col>
                                                                <v-col class="pa-0"><span v-if="rule.input.answer.value" v-html="rule.input.answer.value"/></v-col>
                                                            </v-row>
                                                            </template>
                                                        </template>

                                                        <v-row v-else :key="'qid_'+question.id+'_rule_'+displayRuleIndex">
                                                            <v-col>
                                                                MISSING LOGIC {{displayRule}}
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-container>
                                            </template>                                            
                                        </div>
                                    </v-col>                           
                                </v-row>
                            </v-container>
                        </v-row>
                    </v-container>


                </v-col>
            </v-row>
        </template>
    </v-container>

    <v-dialog v-model="ui.newQuestionSet" max-width="800">
        <v-card>
            <v-card-title>
                New Question Set
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="form.questionSet.questionSetID" @keyup="questionSet.validateQuestionSetID" outlined label="Question Set ID" :rules="validation.questionSetID" required/>
                <v-text-field v-model="form.questionSet.description" outlined label="Description" :rules="validation.required" required/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.newQuestionSet=false">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="questionSet.create" :disabled="(form.questionSet.questionSetID==null || form.questionSet.questionSetID.length==0) || (form.questionSet.description==null || form.questionSet.description.length==0)">Create</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="ui.copyQuestionSet" max-width="800">
        <v-card>
            <v-card-title>
                Copy Question Set
            </v-card-title>
            <v-card-text>
                <v-select v-model="editing.questionSetID" :items="questionSetItems" label="Copy from"/>
                <v-text-field v-model="form.questionSet.questionSetID" @keyup="questionSet.validateQuestionSetID" outlined label="Question Set ID" :rules="validation.questionSetID" required/>
                <v-text-field v-model="form.questionSet.description" outlined label="Description" :rules="validation.required" required/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.copyQuestionSet=false">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="questionSet.copy" :disabled="(form.questionSet.questionSetID==null || form.questionSet.questionSetID.length==0) || (form.questionSet.description==null || form.questionSet.description.length==0)">Copy</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="ui.editQuestion" fullscreen hide-overlay persistent data-ui-element="question editor">
        <v-card v-if="ui.editQuestion">
            <v-card-title>
                Editing Question
            </v-card-title>
            <v-card-subtitle>
                EDITING: #{{editing.qIndex+1}} | QID: {{selection.question.id}}
            </v-card-subtitle>

            <v-card-actions>
                <v-btn text @click="questionSet.cancelEditQuestion">
                    <v-icon>mdi-file-cancel-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                    <v-btn @click="ui.editQuestion=false" text color="success">Accept</v-btn>
            </v-card-actions>            

            <v-card-text>

                <v-switch :true-value="true" :false-value="false" v-model="selection.question.display" :label="'Displayed'"/>
                <v-text-field v-model="selection.question.id" type="number" required :rules="validation.questionID" label="Question ID" outlined/>

                <v-card>
                    <v-card-title>Logics</v-card-title>
                    <v-card-text>
                        <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.canHide" @change="questionSet.logicCleanUp(selection.question.canHide, selection.question.displayLogic)" :label="'Display Logic'"/>
                        <div v-if="selection.question.canHide">
                            Display Logic                        
                            <LogicSelector :logicTemplate="newTemplate('logic')" v-model="selection.question.displayLogic" :logics="logics"/>
                        </div>                            
                    </v-card-text>
                </v-card>
               

                <v-card>
                    <v-card-title>
                        Text
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <h3 align="center">English</h3>
                                </v-col>
                                <v-col>
                                    <h3 align="center">French</h3>
                                </v-col>
                            </v-row>  
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="selection.question.header.text['en-ca']" :label="'Header'"/>
                                    <v-text-field v-model="selection.question.body.text['en-ca']" :label="'Body'"/>
                                    <v-text-field v-model="selection.question.subtext.text['en-ca']" :label="'Subtext'"/>
                                    <v-text-field v-model="selection.question.footer.text['en-ca']" :label="'Footer'"/>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="selection.question.header.text['fr-ca']" :label="'Header'"/>
                                    <v-text-field v-model="selection.question.body.text['fr-ca']" :label="'Body'"/>
                                    <v-text-field v-model="selection.question.subtext.text['fr-ca']" :label="'Subtext'"/>
                                    <v-text-field v-model="selection.question.footer.text['fr-ca']" :label="'Footer'"/>
                                </v-col>
                            </v-row>
                        </v-container>                                        
                    </v-card-text>
                </v-card>


                
                <v-card>
                    <v-card-title>
                        Prompts
                    </v-card-title>
                    <v-card-text>
                        <v-checkbox v-model="selection.question.prompts" v-for="(prompt) in prompts" :key="'prompt_selection_'+prompt.id" :value="{prompt_id:prompt.id, position: 'bottom'}" :label="'['+prompt.id+'] - '+prompt.description"/>

                            <v-list>
                            <draggable v-model="selection.question.prompts">
                                <v-list-item v-for="prompt in selection.question.prompts" :key="'prompt_position_'+prompt.prompt_id">
                                    <v-icon>mdi-drag-horizontal</v-icon>
                                    <v-select v-model="prompt.position" :items="[{text:'top',value:'top'}, {text:'bottom',value:'bottom'}]" :label="'Prompt Position - For Prompt ID: '+prompt.prompt_id"/>
                                </v-list-item>
                            </draggable>
                            </v-list>
                    </v-card-text>
                </v-card>                

                <v-card>
                    <v-card-title>
                        Groups
                    </v-card-title>

                    <v-card-text class="pa-0">
                        <v-container style="max-width: none;">
                            <v-row>
                                <v-col cols="1" class="pa-0">
                                    <v-navigation-drawer
                                        v-model="ui.groupDrawer"
                                        >
                                        <v-list>
                                            <v-list-item>
                                                Groups
                                            </v-list-item>

                                            <v-list-item>
                                                <v-btn small color="success" style="margin: 5px;" @click="newGroup(selection.question.groups)"><v-icon>mdi-plus</v-icon></v-btn>
                                            </v-list-item>
                                        </v-list>
                                        <v-divider/>
                                        
                                        <v-list>
                                            <draggable v-model="selection.question.groups">
                                                <v-list-item v-for="(groupItem, gIndex) in selection.question.groups" :key="'group_drawer_item'+gIndex">
                                                    <v-icon>mdi-drag-horizontal</v-icon>
                                                    <v-btn small @click="ui.question_group_panels=gIndex" :class="[{'hidden':!groupItem.display}]">
                                                        #:{{gIndex+1}} ID:{{groupItem.id}}
                                                    </v-btn>


                                                    <v-btn icon>
                                                        <v-icon @click="deleteItem(gIndex, selection.question.groups, {type:'group',qIndex: editing.qIndex, gIndex})" x-small color="primary lighten-1">mdi-delete</v-icon>
                                                    </v-btn>                                                                      
                                                </v-list-item>
                                            </draggable>
                                        </v-list>

                                    </v-navigation-drawer>
                                </v-col>
                                <v-col class="pa-0">
                                    <v-expansion-panels v-model="ui.question_group_panels">
                                        <v-expansion-panel v-for="(groupItem, gIndex) in selection.question.groups" :key="'panel_gIndex_'+gIndex">

                                            <v-expansion-panel-header>
                                                Group ID:{{groupItem.id}}
                                            </v-expansion-panel-header>

                                            <v-expansion-panel-content>
                                                <v-card>
                                                    <v-card-text>
                                                        <v-select v-model="selection.question.groups[gIndex].type" :items="groupTypes" :label="'Input type'" @change="init" style="width:100%;"/>

                                                        <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="false" v-model="selection.question.groups[gIndex].display" :label="'Displayed'"/>
                                                        <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="false" v-model="selection.question.groups[gIndex].disabled" :label="'Disabled'"/>

                                                        <v-container>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-card>
                                                                        <v-card-title>Requirements</v-card-title>
                                                                        <v-card-text>
                                                                            <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.groups[gIndex].requirements.required" :label="'Required'"/>
                                                                            <v-text-field type="number" v-model="selection.question.groups[gIndex].requirements.min" label="Validation min" clearable></v-text-field>
                                                                            <v-text-field type="number" v-model="selection.question.groups[gIndex].requirements.max" label="Validation max" clearable></v-text-field>
                                                                            
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-card>
                                                                        <v-card-title>Logics</v-card-title>
                                                                        <v-card-text>
                                                                            <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.groups[gIndex].canHide" :label="'Display Logic'"/>
                                                                            <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.groups[gIndex].canDisable" :label="'Disable Logic'"/>
                                                                            <div v-if="selection.question.groups[gIndex].canHide">
                                                                                Display Logic
                                                                                <LogicSelector  :logicTemplate="newTemplate('logic')" v-model="selection.question.groups[gIndex].displayLogic" :logics="logics"/>
                                                                            </div>
                                                                            <div v-if="selection.question.groups[gIndex].canDisable">
                                                                                Disable Logic
                                                                                <LogicSelector  :logicTemplate="newTemplate('logic')" v-model="selection.question.groups[gIndex].disableLogic" :logics="logics"/>
                                                                            </div>
                                                                        </v-card-text>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>


                                                        <v-container>
                                                            <v-row>
                                                                <v-col>
                                                                    <h3 align="center">English</h3>
                                                                </v-col>
                                                                <v-col>
                                                                    <h3 align="center">French</h3>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-text-field v-model="selection.question.groups[gIndex].text['en-ca']" :label="'Group Text'"/>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-text-field v-model="selection.question.groups[gIndex].text['fr-ca']" :label="'Group Text'"/>
                                                                </v-col>
                                                            </v-row>  
                                                        </v-container>                                  
                                                        

                                                        <v-card>
                                                            <v-card-title>
                                                                Choices
                                                            </v-card-title>

                                                            <v-card-text class="pa-0">
                                                                <v-container style="max-width: none;">
                                                                    <v-row>
                                                                        <v-col cols="4">
                                                                            <v-navigation-drawer
                                                                                v-model="ui.choiceDrawer"
                                                                                :width="800"
                                                                            >
                                                                                <v-list>
                                                                                    <v-list-item>
                                                                                        Choices for group ID: {{groupItem.id}}
                                                                                    </v-list-item>

                                                                                    <v-list-item>
                                                                                        <v-btn small @click="newChoice(selection.question.groups[gIndex].choices)" color="primary"><v-icon>mdi-plus</v-icon></v-btn>                        
                                                                                    </v-list-item>
                                                                                </v-list>
                                                                                <v-divider/>
                                                                                
                                                                                <v-list>
                                                                                    <draggable v-model="selection.question.groups[gIndex].choices">
                                                                                        <v-list-item v-for="(choiceItem, cIndex) in selection.question.groups[gIndex].choices" :key="'cIndex_'+cIndex">
                                                                                            <v-icon>mdi-drag-horizontal</v-icon>

                                                                                            <v-btn style="width:90%;" @click="questionSet.scrollToChoice(choiceItem)" small :class="[{'hidden':!choiceItem.display}]">
                                                                                                <div style="text-align: left; width:100%;">
                                                                                                    <span style="font-size: 0.7em">#:{{cIndex+1}} ID:{{choiceItem.id}}</span> <br> {{choiceItem.text[language]}}
                                                                                                </div>
                                                                                            </v-btn>    

                                                                                            <v-btn @click="deleteItem(cIndex, selection.question.groups[gIndex].choices, {type:'choice',qIndex: editing.qIndex, gIndex, cIndex})" icon :disabled="selection.question.groups[gIndex].choices.length<=1">
                                                                                                <v-icon color="error lighten-1">mdi-delete</v-icon>
                                                                                            </v-btn>                                                                       
                                                                                        </v-list-item>
                                                                                    </draggable>
                                                                                </v-list>

                                                                            </v-navigation-drawer>                                                                                        
                                                                            
                                                                        </v-col>
                                                                        <v-col>
                                                                            <v-card v-for="(choiceItem, cIndex) in selection.question.groups[gIndex].choices" style="margin-bottom: 25px;" :data-question-choice="choiceItem.id" :key="'cIndex_'+cIndex">
                                                                                <v-card-subtitle>
                                                                                    Choice #{{cIndex+1}} | ID:{{choiceItem.id}}
                                                                                </v-card-subtitle>

                                                                                <v-card-text class="pa-0">
                                                                                    <v-container>
                                                                                        <v-row>
                                                                                            <v-col>
                                                                                                <v-btn icon @click="questionSet.editChoice(editing.qIndex, gIndex, cIndex)" small><v-icon>mdi-cog-outline</v-icon></v-btn>
                                                                                                <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="false" v-model="selection.question.groups[gIndex].choices[cIndex].exclusive" :label="'Exclusive'"/>
                                                                                                <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="false" v-model="selection.question.groups[gIndex].choices[cIndex].other" :label="'Other'"/>
                                                                                            </v-col>
                                                                                            <v-col align="right">
                                                                                                <v-btn @click="deleteItem(cIndex, selection.question.groups[gIndex].choices, {type:'choice',qIndex: editing.qIndex, gIndex, cIndex})" icon :disabled="selection.question.groups[gIndex].choices.length<=1">
                                                                                                    <v-icon color="error lighten-1">mdi-delete</v-icon>
                                                                                                </v-btn>                                                                                           
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row>
                                                                                            <v-col>
                                                                                                <v-text-field v-if="!['number','textfield'].includes(selection.question.groups[gIndex].type)" v-model="selection.question.groups[gIndex].choices[cIndex].value" outlined required :rules="validation.required" :label="'Value'"/>
                                                                                                <h3 align="center" v-else>N/A</h3>
                                                                                            </v-col>
                                                                                            <v-col>
                                                                                                <v-text-field v-model="selection.question.groups[gIndex].choices[cIndex].text['en-ca']" :label="'English Text'"/>
                                                                                            </v-col>
                                                                                            <v-col>
                                                                                                <v-text-field v-model="selection.question.groups[gIndex].choices[cIndex].text['fr-ca']" :label="'French Text'"/>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                        <v-row v-if="selection.question.groups[gIndex].choices[cIndex].other">
                                                                                            <v-col>
                                                                                                <v-text-field v-model="selection.question.groups[gIndex].choices[cIndex].other_text['en-ca']" :label="'Please specify - English'"/>
                                                                                            </v-col>
                                                                                            <v-col>
                                                                                                <v-text-field v-model="selection.question.groups[gIndex].choices[cIndex].other_text['fr-ca']" :label="'Please specify - French'"/>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-container>   
                                                                                </v-card-text>
                                                                            </v-card>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-card-text>
                                                        </v-card>

                                                    </v-card-text>
                                                </v-card>
                                            </v-expansion-panel-content>

                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn @click="ui.editQuestion=false" text color="error">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

    </v-dialog>

    <v-dialog v-model="ui.editChoice" fullscreen hide-overlay persistent>
        <v-card v-if="editing.qIndex!=null && editing.gIndex!=null && editing.cIndex!=null">
            <v-card-text>
                
                <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="false" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].display" :label="'Display'"/>
                <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="false" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].disabled" :label="'Disabled'"/>

                <v-container>
                    <v-row>
                        <v-col>
                            <v-card>
                                <v-card-title>Requirements</v-card-title>
                                <v-card-text>
                                    <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].requirements.required" :label="'Required'"/>
                                    <v-text-field type="number" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].requirements.min" label="min"></v-text-field>
                                    <v-text-field type="number" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].requirements.max" label="max"></v-text-field>
                                    
                                </v-card-text>
                            </v-card>
                        </v-col>                                                                                    
                        <v-col>
                            <v-card>
                                <v-card-title>Logics</v-card-title>
                                <v-card-text>
                                        <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].canHide" :label="'Display Logic'"/>
                                        <v-switch style="display: inline-block; margin: 15px;" :true-value="true" :false-value="null" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].canDisable" :label="'Disable Logic'"/>
                                        <div v-if="selection.question.groups[editing.gIndex].choices[editing.cIndex].canHide">
                                            Display Logic
                                            <LogicSelector  :logicTemplate="newTemplate('logic')" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].displayLogic" :logics="logics"/>
                                        </div>
                                        <div v-if="selection.question.groups[editing.gIndex].choices[editing.cIndex].canDisable">
                                            Disable Logic
                                            <LogicSelector  :logicTemplate="newTemplate('logic')" v-model="selection.question.groups[editing.gIndex].choices[editing.cIndex].disableLogic" :logics="logics"/>
                                        </div>
                                </v-card-text>
                            </v-card>                                                                                        
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn @click="ui.editChoice=false" text color="error">OK</v-btn>
                <v-spacer></v-spacer>

            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="ui.editLogic" fullscreen hide-overlay persistent>
        <v-card v-if="ui.editLogic && editing.logicIndex!=null">
            <v-card-title>
                {{logics[editing.logicIndex].description}}
            </v-card-title>
            <v-card-subtitle>
                ID: {{editing.logicIndex}}
            </v-card-subtitle>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="logics[editing.logicIndex].description" required :rules="validation.required" label="Logic description"></v-text-field>
                            <v-select :items="logicRequirementItems" v-model="logics[editing.logicIndex].requirement" label="Requirement"/>
                            <v-btn icon @click="addRule(logics[editing.logicIndex].rules)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-card v-for="(rule, ruleIndex) in logics[editing.logicIndex].rules" :key="'rule_'+ruleIndex" style="margin: 15px;">
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="5">
                                                <v-select :items="logicSourceItems" v-model="rule.source.type" label="Input Source"/>
                                                <template v-if="rule.source.type=='questionMap'">
                                                    <v-select @change="rule.value=[]; rule.source.input.group_id=null; rule.source.input.choice_id=null; $forceUpdate()" v-model="rule.source.input.question_id" :items="inputSourceItems_questions" label="Question"/>
                                                    <v-select @change="rule.value=[]; rule.source.input.choice_id=null; $forceUpdate()" v-if="rule.source.input.question_id" v-model="rule.source.input.group_id" :items="inputSourceItems_question_groups(rule.source.input.question_id)" label="Group"/>
                                                    <template v-if="rule.source.input.question_id && rule.source.input.group_id && questionMap[rule.source.input.question_id].form.group[rule.source.input.group_id].value!=null && typeof questionMap[rule.source.input.question_id].form.group[rule.source.input.group_id].value=='object' && questionMap[rule.source.input.question_id].form.group[rule.source.input.group_id].value.length==undefined">
                                                        <v-select @change="rule.value=[]; $forceUpdate()" v-if="rule.source.input.question_id && rule.source.input.group_id" v-model="rule.source.input.choice_id" :items="inputSourceItems_question_group_choices(questionMap[rule.source.input.question_id], rule.source.input.group_id)" label="Choice"/>
                                                    </template>
                                                </template>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-select :items="logicOperatorItems" v-model="rule.operator" label="Operator"/>
                                            </v-col>
                                            <v-col cols="5">
                                                <template v-if="rule.source.type=='questionMap' && rule.source.input.question_id && rule.source.input.group_id">
                                                    <template v-if="!(questionMap[rule.source.input.question_id].form.group[rule.source.input.group_id].value!=null && typeof questionMap[rule.source.input.question_id].form.group[rule.source.input.group_id].value=='object' && questionMap[rule.source.input.question_id].form.group[rule.source.input.group_id].value.length==undefined)">
                                                        <template v-for="(value, v) in rule.value">
                                                            <v-checkbox v-if="choiceMap(questionMap[rule.source.input.question_id], rule.source.input.group_id).find((item)=>{return item.value==value})==undefined" dense v-model="rule.value" :label="'*** ['+value+'] NOT FOUND ***'" :key="'input_value_not_found_selector_'+v" :value="value"/>
                                                        </template>

                                                        <v-checkbox v-for="choice in choiceMap(questionMap[rule.source.input.question_id], rule.source.input.group_id)" dense v-model="rule.value" :label="choice.text[language]" :key="'input_value_selector_'+choice.id" :value="choice.value ? choice.value : choice.id"/>
                                                            
                                                        
                                                    </template>
                                                    <template v-else>
                                                        <v-textarea outlined @keydown="editing.ruleIndex = ruleIndex" @keyup="setRuleValue" label="Seperate multiple entries by new line"/>
                                                            {{rule.value}}
                                                    </template>
                                                </template>
                                                <template v-if="rule.source.type=='otherLogic'">
                                                    <v-checkbox dense  v-for="(logic, logicIndex) in logics" :key="'logic_selector_'+logicIndex" v-model="rule.value" :label="logic.description" v-show="logics[editing.logicIndex].id!=logic.id" :value="logic.id"/>
                                                    <!-- <LogicSelector :logicTemplate="newTemplate('logic')" v-model="rule.value" :logics="logics"/> -->
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="deleteItem(ruleIndex, logics[editing.logicIndex].rules)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="logicHandler();ui.editLogic=false;">
                    Close
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    
    </v-dialog>

    <v-dialog v-model="ui.editPrompt" fullscreen hide-overlay persistent>
        <v-card v-if="editing.promptIndex!=null">
            <v-card-title>
                Editing Prompt: {{prompts[editing.promptIndex].id}}
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-text-field v-model="prompts[editing.promptIndex].description" label="Prompt Description"/>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea outlined v-model="prompts[editing.promptIndex].content['en-ca']" id="prompt_content_en" label="Prompt Content"/>
                            Make selection show on logic:<br>
                            <v-btn x-small style="margin: 5px;" v-for="(logic, logicIndex) in logics" @click="questionSet.wrap('prompt_content_en',logicIndex, editing.promptIndex, 'en-ca')" :key="'wrapper_en_'+logicIndex">[{{logicIndex}}] - {{logic.description}}</v-btn>
                        </v-col>
                        <v-col>
                            <v-textarea outlined v-model="prompts[editing.promptIndex].content['fr-ca']" id="prompt_content_fr" label="Prompt Content"/>
                            Make selection show on logic:<br>
                            <v-btn x-small style="margin: 5px;" v-for="(logic, logicIndex) in logics" @click="questionSet.wrap('prompt_content_fr',logicIndex, editing.promptIndex, 'fr-ca')" :key="'wrapper_fr_'+logicIndex">[{{logicIndex}}] - {{logic.description}}</v-btn>
                        </v-col>
                    </v-row>
                </v-container>

                <v-switch v-model="prompts[editing.promptIndex].display" :true-value="true" :false-value="false" label="Display"/>
                <LogicSelector :logicTemplate="newTemplate('logic')" v-model="prompts[editing.promptIndex].displayLogic" :logics="logics"/>
                <ReferenceSelector :referenceTemplate="newTemplate('reference')" v-model="prompts[editing.promptIndex].references" :references="references"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.editPrompt=false; editing.promptIndex=null">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="deleteItem(editing.promptIndex, prompts, 'prompt');ui.editPrompt=false; editing.promptIndex=null"><v-icon>mdi-delete</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog v-model="ui.editReference" fullscreen hide-overlay persistent>
        <v-card v-if="editing.referenceIndex!=null">
            <v-card-title>
                Editing Reference
            </v-card-title>
            <v-card-subtitle>
                ID: {{references[editing.referenceIndex].id}}
            </v-card-subtitle>

            <v-card-text>
                <v-text-field v-model="references[editing.referenceIndex].description" label="Description" required :rules="validation.required"/>
                <v-container>
                    <v-row>
                        <v-col>                            
                            <v-text-field v-for="lang in config.languages" :key="'reference_content_'+lang" v-model="references[editing.referenceIndex].content[lang]" :label="lang" required :rules="validation.required"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.editReference=false; editing.referenceIndex=null">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-dialog v-model="ui.editAsset" fullscreen hide-overlay persistent>
        <v-card v-if="editing.assetIndex!=null">
            <v-card-title>
                Editing Asset
            </v-card-title>
            <v-card-subtitle>
                ID: {{assets[editing.assetIndex].id}}
            </v-card-subtitle>

            <v-card-text>
                <v-text-field v-model="assets[editing.assetIndex].description" label="Description" required :rules="validation.required"/>
                <v-container>
                    <v-row>
                        <v-col>
                            Preview
                            <img v-if="assets[editing.assetIndex].content[language]" :src="assets[editing.assetIndex].content[language]" style="max-width:1024px;"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <fileUpload v-model="assets[editing.assetIndex].content[language]" :editLanguage="language" :type="'asset'" :config="config.images"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.editAsset=false; editing.assetIndex=null">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import draggable from 'vuedraggable'
import LogicSelector from './LogicSelector'
import ReferenceSelector from './ReferenceSelector'
import Question from '../Question'
import Survey from '../QuestionSet'
import Prompt from '../Prompt'
import functions from '../functions'
// import Tiny from '@tinymce/tinymce-vue'


let surveyFunctions = new function(){

    this.cache = function(key,questions){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache') ? JSON.parse(storage.getItem('survey_editor_cache')) : {}

        cache[key] = {
            questions: questions
        };
        
        storage.setItem('survey_editor_cache',JSON.stringify(cache));
        console.log('cached')
    }

    this.loadQuestions = function(key){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache');
        if(cache){
            cache = JSON.parse(cache);
            if(cache[key]){
                return cache[key].questions
            }
        }
        return false
    }

    this.scrollToQuestion = function(question){
      let y = document.querySelector("[data-question-id='"+question.id+"']").offsetParent.offsetTop
      window.scrollTo({top: y, behavior:'smooth'})
    }

    this.scrollToQuestionGroup = function(question, group){
      let y = document.querySelector("[data-question-id='"+question.id+"'] [data-group-id='"+group.id+"']").offsetTop
      window.scrollTo({top: y, behavior:'smooth'})
    }

    this.highlightQuestionON = function(question){
      document.querySelector("[data-question-id='"+question.id+"']").parentElement.classList.add('highlight')
    }

    this.highlightQuestionOFF = function(question){
      document.querySelector("[data-question-id='"+question.id+"']").parentElement.classList.remove('highlight')
    }

}

let questionSet = function(self){

    this.save = async function(){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache') ? JSON.parse(storage.getItem('survey_editor_cache')) : {}

        // cache[self.editing.questionSetID] = {
        //     questions: self.questions,
        //     logics: self.logics,
        //     prompts: self.prompts,
        //     references: self.references,
        //     assets: self.assets
        // };

        cache[self.editing.questionSetID] = self.selection.questionSet
        
        storage.setItem('survey_editor_cache',JSON.stringify(cache));

        let action = self.selection.questionSet.id ? 'update' : 'new'
        let response = await self.sendRequest(action=='new' ? 'post' : 'patch', `/api/questionSet/admin/${action}`, self.selection.questionSet)
        console.log(response)
    }

    this.remove = function(questionSetID){
        if(confirm('Confirm Deletion')==true){
            let storage = window.localStorage;
            let cache = storage.getItem('survey_editor_cache');
            if(cache){
                cache = JSON.parse(cache);
                if(cache[questionSetID]){
                    delete cache[questionSetID]
                    storage.setItem('survey_editor_cache',JSON.stringify(cache));
                    window.location.reload()
                    return true
                }
            }
            return false
        }
        return false

    }

    this.load = function(questionSetID){
        // let storage = window.localStorage;
        // let cache = storage.getItem('survey_editor_cache');
        // if(cache){
        //     cache = JSON.parse(cache);
        //     if(cache[questionSetID]){
        //         self.questions = cache[questionSetID].questions
        //         self.logics = cache[questionSetID].logics ? cache[questionSetID].logics : []
        //         self.prompts = cache[questionSetID].prompts ? cache[questionSetID].prompts : []
        //         self.references = cache[questionSetID].references ? cache[questionSetID].references : []
        //         self.assets = cache[questionSetID].assets ? cache[questionSetID].assets : []
        //     }else{
        //         alert('Unable to load questionSet')
        //     }
        // }
        self.selection.questionSet = self.questionSetMap[questionSetID]
        self.questions = self.selection.questionSet.questions
        self.logics = self.selection.questionSet.logics
        self.prompts = self.selection.questionSet.prompts
        self.references = self.selection.questionSet.references
        self.assets = self.selection.questionSet.assets

        self.init()
    }

    this.create = function(){
        self.questions = []
        self.editing.questionSetID = self.form.questionSet.questionSetID
        self.questionSet.save()
        window.location.reload()
    }

    this.copy = function(){
        self.questionSet.load(self.editing.questionSetID)
        self.editing.questionSetID = self.form.questionSet.questionSetID
        self.questionSet.save()
        window.location.reload()
    }

    this.validateQuestionSetID = function(){
        let isValid = true
        let questionSetMap = self.questionSetMap
        let questionSetID = self.form.questionSet.questionSetID
        if(questionSetMap[questionSetID]){
            isValid = false
        }

        return isValid
    }

    this.validateQuestionID = function(v){
        let ids = {}
        for(let i=0; i<self.questions.length; i++){
            let questionID = self.questions[i].id
            if(ids[questionID]){
                ids[questionID]+=1
            }else{
                ids[questionID] = 1
            }
        }
        
        return ids[v]==1 && parseInt(v,10)>0
    }

    this.editQuestion = function(qIndex){
        self.backup()
        self.editing.qIndex = qIndex
        self.selection.question = self.selection.questionSet.questions[qIndex]
        self.ui.editQuestion = true
        self.ui.question_group_panels = 0
    }

    this.editChoice = function(qIndex, gIndex, cIndex){
        self.editing.gIndex = gIndex,
        self.editing.cIndex = cIndex
        self.ui.editChoice = true
    }

    this.scrollToChoice = function(choice){
        let dialog = document.querySelector(".v-dialog--fullscreen")
        let element = document.querySelector("[data-question-choice='"+choice.id+"']")
        dialog.scrollTop = (element.offsetParent.offsetHeight)
    }

    this.cancelEditQuestion = function(){
        self.editing.qIndex=null;
        self.ui.editQuestion=false
        self.restore()
    }

    this.newPrompt = function(){
        let prompt = self.newTemplate('prompt')
        prompt.displayLogic = self.newTemplate('logic')
        prompt.id = self.assignID(self.prompts,'id')
        self.prompts.push(prompt)
        self.questionSet.editPrompt(self.prompts.length-1)
    }

    this.editPrompt = function(promptIndex){
        self.editing.promptIndex = promptIndex
        self.ui.editPrompt=true
    }

    this.wrap = function(id, logicID, promptIndex, language){
        function getInputSelection(el) {
            var start = 0, end = 0, normalizedValue, range,
                textInputRange, len, endRange;

            if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
                start = el.selectionStart;
                end = el.selectionEnd;
            } else {
                range = document.selection.createRange();

                if (range && range.parentElement() == el) {
                    len = el.value.length;
                    normalizedValue = el.value.replace(/\r\n/g, "\n");

                    // Create a working TextRange that lives only in the input
                    textInputRange = el.createTextRange();
                    textInputRange.moveToBookmark(range.getBookmark());

                    // Check if the start and end of the selection are at the very end
                    // of the input, since moveStart/moveEnd doesn't return what we want
                    // in those cases
                    endRange = el.createTextRange();
                    endRange.collapse(false);

                    if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                        start = end = len;
                    } else {
                        start = -textInputRange.moveStart("character", -len);
                        start += normalizedValue.slice(0, start).split("\n").length - 1;

                        if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                            end = len;
                        } else {
                            end = -textInputRange.moveEnd("character", -len);
                            end += normalizedValue.slice(0, end).split("\n").length - 1;
                        }
                    }
                }
            }

            return {
                start: start,
                end: end
            };
        }

        function replaceSelectedText(el, text) {

            var sel = getInputSelection(el), val = el.value;
            el.value = val.slice(0, sel.start) + text + val.slice(sel.end);
        }

        let el = document.getElementById(id)
        var sel = getInputSelection(el), val = el.value
        let text = val.slice(sel.start, sel.end)

        replaceSelectedText(el,"<span logic='"+logicID+"'>"+text+"</span>")

        self.prompts[promptIndex].content[language] = el.value
    }

    this.logicCleanUp = function(canHide, displayLogic){
        if(!canHide){
            displayLogic.rules = []
        }
    }


}

let newTemplate = function(type){
    // let languages = this.config.languages
    let languages = ['en-ca','fr-ca']
    let output = {}

    switch(type){
        default:
            console.error('Missing template type')
        break;

        case"question":
            output = {
                id: null,
                header: {text: this.newTemplate('text')},
                body: {text: this.newTemplate('text')},
                subtext: {text: this.newTemplate('text')},
                footer: {text: this.newTemplate('text')},
                groups: [],
                display: true,
                canHide: false,
                displayLogic: null, //this.newTemplate('logic'),
                actions: null,
                system: false
            }
            this.newGroup(output.groups)
        break;

        case"group":
            output = {
                id: null,
                type: null,
                text: this.newTemplate('text'),
                choices: [],
                requirements: this.newTemplate('requirements'),
                display: true,
                disabled: false,
                canHide: false,
                canDisable: false,
                displayLogic: null, //this.newTemplate('logic'),
                disableLogic: null, //this.newTemplate('logic'),
            }
            this.newChoice(output.choices)
        break;

        case"choice":
            output = {
                id: null,
                text: this.newTemplate('text'),
                value: null,
                exclusive: false,
                other: false,
                other_text: this.newTemplate('text'),
                display: true,
                disabled: false,
                canHide: false,
                canDisable: false,                        
                displayLogic: null, //this.newTemplate('logic'),
                disableLogic: null, //this.newTemplate('logic'),
                requirements: this.newTemplate('requirements'),
            }
            output.other_text['en-ca'] = "Please specify"
            output.other_text['fr-ca'] = "Veuillez préciser"                    
        break;

        case"text":
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language] = null
            }
        break;

        case"logic":
            output = {
                requirement: "allTrue",
                description: null,
                x: null,
                rules: []
            }
        break;

        case"requirements":
            output = {
                required: true,
                min: null,
                max: null
            }
        break;

        case"prompt":
            output = {
                id: null,
                description: null,
                display: false,
                disabled: false,
                fullscreen: false,
                buttons: {
                    resetQuestions: {
                        enabled: false,
                        questions: []
                    },
                    disablePrompt: {
                        enabled: false
                    }
                },
                content: {}
            }

            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output.content[language] = null
            }            
        break;

        case"reference":
            output = {
                id: null,
                description: null,
                content: {
                    en: null,
                    fr: null
                }
            }
        break;

        case"asset":
            output = {
                id: null,
                type: 'image',
                description: null,
                content: {
                    en: null,
                    fr:null
                }
            }
        break;
    }

    return output
}

export default {
    components: {
        LogicSelector,
        ReferenceSelector,
        Question,
        Survey,
        Prompt,
        draggable,
        // Tiny
    },
    created: function(){
        if(this.$route.params.questionSet){
            this.editing.questionSetID = this.$route.params.questionSet
        }
        this.initialize()        
    },
    data: function(){
        return {
            ready: false,
            editing: {
                questionSetID: null,
                qIndex: null,
                gIndex: null,
                cIndex: null,
                logicIndex: null,
                ruleIndex: null,
                promptIndex: null,
                referenceIndex: null,
                assetIndex: null
            },
            ui: {
                panels: [],
                question_group_panels: [],
                preview: false,
                debug: false,
                newQuestionSet: false,
                copyQuestionSet: false,
                questionNav: true,
                groupDrawer: true,
                choiceDrawer: true,
                editQuestion: false,
                editChoice: false,
                editLogic: false,
                editPrompt: false,
                editReference: false,
                editAsset: false,            
                triggers: {}
            },
            form: {
                questionSet: {
                    questionSetID: null,
                    description: null
                }
            },
            validation: {
                required: [
                    v => !!v || 'Required Field'
                ],
                questionSetID: [
                    v => !!v || 'Required Field',
                    v => this.questionSet.validateQuestionSetID(v) || 'Invalid Question Set ID'
                ],
                questionID: [
                    v => !!v || 'Required Field',
                    v => this.questionSet.validateQuestionID(v) || 'Question ID is in use or invalid'
                ]
            },
            config: {
                languages: ['en-ca','fr-ca'],
                groupTypes: ['checkbox','radio','textfield','number','number-slider','toggle','rating','ranking','calendar'],
                images: {
                    imageType: "image/x-png",
                    imageType_string: "image/png"
                }
            },
            questions: [],
            logics: null,
            prompts: [],
            references: [],
            assets: [],
            hub: {
                logicIndex: undefined
            },
            timers: {
                displayLogic: null,
                reset: null,
                ruleValue: null
            },
            undo: null,
            questionSets: null,
            selection: {
                questionSet: null,
                question: null,
                group: null,
                choice: null,
                logic: null,
                rule: null,
                prompt: null,
                reference: null,
                asset: null
            }
        }
    },
    methods: {
        initialize: async function(){
            this.questionSets = await this.getQuestionSets()
            
        },
        init: function(){
            let self = this
            self.ready = false
            functions.initializeQuestions(self, self.questions, self.logics)
        },
        getQuestionSets: async function(){
            let self = this
            async function getData(){
                let response = await self.sendRequest('get','/api/questionSet/admin/list')
                return response.status==200 ? response.data : []
            }

            let questionSets = await getData()
            return questionSets

        },
        logicHandler: function(){
            let self = this
            functions.logicHandler({questionMap:self.questionMap, logicMap:self.logicMap}, self.logics)
        },
        displayHandler: function(data){
            if(this.isPreview){
                let self = this
                clearTimeout(this.timers.displayLogic)
                self.timers.displayLogic = setTimeout(function(){
                    functions.logicHandler({questionMap:self.questionMap, logicMap:self.logicMap}, self.logics)
                    functions.displayHandler(data, self.logics, self.questionMap)
                    functions.disableHandler(data, self.logics, self.questionMap)
                    functions.promptDisplayHandler(data, self.logics, self.promptMap)
                    self.$forceUpdate()
                },100)
            }
        },
        newQuestion: function(){
            this.backup()
            // let questions = this.questions
            let questions = this.selection.questionSet.questions
            let output = this.newTemplate('question');
            output.id = this.assignID(questions,'id')
            questions.push(output)
            // this.editQuestion(questions.length-1)
            this.$forceUpdate()
            
        },
        newGroup: function(array){
            let output = this.newTemplate('group')
            output.id = this.assignID(array,'id')
            array.push(output)
            this.ui.question_group_panels = array.length-1
        },
        newChoice: function(array){
            let output = this.newTemplate('choice')
            output.requirements.required=false
            output.id = this.assignID(array,'id')
            array.push(output)
        },
        updateLogics: function(data){
            let question = this.questions[this.editing.qIndex];
            if(data.type=='question'){
                question.displayLogic = data.displayLogic
            }
        },
        updateActions: function(data){
            console.log('updateActions',data)
            let question = this.questions[this.editing.qIndex];
            if(data.type=='question'){
                question.actions = data.actions
            }
        },
        assignID: function(array, key='id'){
            let ids = []
            for(let i=0; i<array.length; i++){
                let item = array[i]
                if(item[key]){
                    ids.push(item[key])
                }
            }
            ids.sort(function(a, b){return a-b});

            let newID = 1
            for(let i=0; i<ids.length; i++){
                let id = ids[i]
                if(id==newID){
                    newID++
                }
            }

            return newID;
        },
        editQuestion: function(qIndex){
            this.editing.qIndex = qIndex
            this.ui.editQuestion = true
        },
        confirm: function(callBack){
            if (confirm("CONFRIM DELETE") == true) {
                callBack()
            }
        },
        deleteItem: function(index, items, meta){
            let questionResources = this.questionResources
            let logicResources = this.logicResources
            let promptMap = this.promptMap
            let self = this
            let allow = true
            let message = ''

            if(typeof meta=='string'){
                if(meta=='question'){
                    message = 'Question is in use by logic ID(s): '
                    let question = items[index]
                    for(let logicID in logicResources){
                        let logic = logicResources[logicID]
                        
                        if(logic.questions[question.id]!=undefined){
                            message+=logicID+', '
                            allow = false
                        }
                    }
                }
    
                if(meta=='prompt'){
                    message = 'Prompt is in use by question ID(s): '
                    let prompt = items[index]
                    for(let qid in questionResources){
                        let question = questionResources[qid]
                        if(question.prompts.includes(prompt.id)){
                            message+=qid+', '
                            allow = false
                        }
                    }
                }
    
                if(meta=='logic'){
                    message = 'Logic is in use by question ID(s): '
                    let logic = items[index]
                    for(let qid in questionResources){
                        let question = questionResources[qid]
                        if(question.logics.includes(logic.id)){
                            message+=qid+', '
                            allow = false
                        }
                    }
                }

                if(meta=='reference'){
                    message = "Reference is in use by prompt ID(s): "
                    let reference = items[index]
                    console.log('deleting', {reference})
                    for(let promptID in promptMap){
                        let prompt = promptMap[promptID]
                        console.log('checkiing prompt',prompt)
                        if(prompt.references.includes(reference.id)){
                            message+=promptID+', '
                            allow = false
                        }
                    }
                }
            }


            if(typeof meta=='object'){
    
                if(meta.type=='group'){
                    message = 'Group is in use by logic ID(s): '
                    let question = this.questions[this.editing.qIndex]
                    let group = question.groups[meta.gIndex]

                    for(let logicID in logicResources){
                        let logic = logicResources[logicID]
                        
                        if(logic.questions[question.id] && logic.questions[question.id].groups[group.id]){
                            message+=logicID+', '
                            allow = false
                        }
                    }
                }
    
                if(meta.type=='choice'){
                    message = 'Choice is in use by logic ID(s): '
                    let question = this.questions[this.editing.qIndex]
                    let group = question.groups[meta.gIndex]

                    for(let logicID in logicResources){
                        let logic = logicResources[logicID]
                                             
                        if(logic.questions[question.id] && logic.questions[question.id].groups[group.id] && logic.questions[question.id].groups[group.id].choices.includes(items[index].id)){
                            message+=logicID+', '
                            allow = false
                        }
                    }
                }

            }

            if(allow){
                this.confirm(()=>{
                    self.backup()
                    if(typeof items=='object' && items.length!=undefined){
                        items.splice(index, 1)
                    }else if(typeof items=='object' && items.length==undefined){
                        delete items[index]
                    }
                    self.$forceUpdate()
                })
            }else{
                alert(message)
            }
        },
        backup: function(){
            this.undo = JSON.stringify({
                questions: this.questions,
                hub: this.hub,
                logics: this.logics,
                prompts: this.prompts,
                references: this.references,
                assets: this.assets
            })            
        },
        restore: function(){
            let self = this
            let restore = JSON.parse(self.undo)
            console.log('restore',restore)
            self.questions = restore.questions
            self.logics = restore.logics
            self.prompts = restore.prompts
            self.references = restore.references
            self.assets = restore.assets
            self.undo = null
            self.init()
        },
        survey: function(){
            return surveyFunctions
        },
        editLogic: function(logicIndex){
            this.ui.editLogic = true
            this.editing.logicIndex = logicIndex
        },
        addLogic: function(){
            let self = this
            self.backup()
            let logic = this.newTemplate('logic')
            logic.id = this.assignID(this.logics,'id')
            this.logics.push(logic)
            this.editLogic(this.logics.length-1)
            // let logics = this.logics;
            // let keys = Object.keys(logics).sort()
            // let id = 1
            // for(let i=0; i<keys.length; i++){
            //     let key = keys[i]
            //     let match = id==key
            //     if(!match){
            //         break;
            //     }
            //     id++
            // }

            // this.logics[id] = this.newTemplate('logic')
            // this.editLogic(id)
        },
        addRule: function(rules){
            let ruleTemplate = function(){
                return {
                    operator: null,
                    source: {
                        type: "questionMap",
                        input: {}
                    },
                    value: []
                }
            }
            rules.push(ruleTemplate())
            this.$forceUpdate()
        },
        setRuleValue: function(e){
            let input = e.target._value;
            let output = input.split('\n')
            let rule = this.logics[this.editing.logicIndex].rules[this.editing.ruleIndex]
            rule.value = output
            console.log('setRuleValue',{
                input,output,rule
            })
        },
        inputSourceItems_question_groups: function(question_id){
            let language = this.language
            let question = this.questionMap[question_id]
            let output = []

            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                output.push({
                    text: group.text[language] ? group.text[language] : group.id,
                    value: group.id
                })
            }

            return output
        },
        inputSourceItems_question_group_choices: function(question, group_id){
            let language = this.language
            let output = []
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                if(group.id == group_id){
                    let choices = group.choices

                    for(let c=0; c<choices.length; c++){
                        let choice = choices[c]
                        output.push({
                            text: choice.text[language],
                            value: choice.id
                        })
                    }
                }
            }

            return output
        },
        choiceMap: function(question, group_id){
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                if(group.id == group_id){
                    return group.choices
                }
            }

            return null
        },
        groupMap: function(question, group_id){
            for(let g=0; g<question.groups.length; g++){
                let group = question.groups[g]
                if(group.id == group_id){
                    return group
                }
            }

            return null
        },
        addReference: function(){
            let reference = newTemplate('reference')
            reference.id = this.assignID(this.references,'id')
            this.references.push(reference)
            this.editReference(this.references.length-1)
        },
        editReference: function(index){
            this.editing.referenceIndex = index
            this.ui.editReference = true
        },
        addAsset: function(){
            let asset = newTemplate('asset')
            asset.id = this.assignID(this.assets,'id')
            this.assets.push(asset)
            this.editAsset(this.assets.length-1)
        },
        editAsset: function(index){
            this.editing.assetIndex = index
            this.ui.editAsset = true
        }
    },
    computed: {
        questionSet: function(){
            let tools = new questionSet(this)
            return tools
        },
        newTemplate: function(){
            return newTemplate
        },
        debug: function(){
            return this.$store.getters.debug || this.ui.debug
        },
        isPreview: function(){
            return this.ui.preview
        },
        questionMap: function(){
            let questions = this.questions
            let output = {}

            if(questions){
                for(let i=0; i<questions.length; i++){
                let question = questions[i]
                output[question.id] = question
                }

            }
            return output
        },
        promptMap: function(){
            let prompts = this.prompts
            let output = {}
            for(let i=0; i<prompts.length; i++){
                let prompt = prompts[i]
                output[prompt.id] = prompt
            }

            return output
        },
        referenceMap: function(){
            let references = this.references
            let output = {}
            for(let i=0; i<references.length; i++){
                let reference = references[i]
                output[reference.id] = reference
            }

            return output
        },
        logicMap: function(){
            let logics = this.logics
            let output = {}

            for(let i=0; i<logics.length; i++){
                let logic = logics[i]
                output[logic.id] = logic
            }

            return output
        },
        assetMap: function(){
            let array = this.assets
            let output = {}

            for(let i=0; i<array.length; i++){
                let item = array[i]
                output[item.id] = item
            }

            return output
        },
        questionSetMap: function(){
            let questionSets = this.questionSets
            let output = {}
            for(let i=0; i<questionSets.length; i++){
                let questionSet = questionSets[i]
                output[questionSet.id] = questionSet
            }
            return output
        },
        questionSetItems: function(){
            let output = [];
            let questionSets = this.questionSets

            if(questionSets){
                for(let i=0; i<questionSets.length; i++){
                    let questionSet = questionSets[i]
                    output.push({
                        text: questionSet.title[this.language],
                        value: questionSet.id
                    })
                }
            }

            // let storage = window.localStorage;
            // let cache = storage.getItem('survey_editor_cache') ? JSON.parse(storage.getItem('survey_editor_cache')) : {}

            // if(cache){
            //     for(let key in cache){
            //         output.push({
            //             text: key,
            //             value: key
            //         })
            //     }
            // }

            

            return output
        },
        language: function(){
            return this.$store.getters.language
        },
        // editingQuestion: function(){
        //     let questions = this.questions
        //     let qIndex = this.editing.qIndex
        //     if(qIndex!=null){
        //         return questions[qIndex]
        //     }else{
        //         return false
        //     }
        // },
        groupTypes: function(){
            let groupTypes = this.config.groupTypes
            let output = []
            for(let i=0; i<groupTypes.length; i++){
                let item = groupTypes[i]
                output.push({
                    text: item,
                    value: item
                })
            }

            return output
        },
        logicRequirementItems: function(){
            let output = [
                {
                    text: 'allTrue',
                    value: 'allTrue'
                },
                {
                    text: 'anyTrue',
                    value: 'anyTrue'
                },
                {
                    text: 'noneTrue',
                    value: 'noneTrue'
                },
                {
                    text: 'xTrue',
                    value: 'xTrue'
                }
            ]

            return output
        },
        logicOperatorItems: function(){
            let output = [
                {
                    text: "=",
                    value: "equals"
                },
                {
                    text: "!=",
                    value: "!equals"
                },
                {
                    text: ">",
                    value: "gt"
                },
                {
                    text: "≥",
                    value: "gte"
                },
                {
                    text: "<",
                    value: "lt"
                },
                {
                    text: "≤",
                    value: "lte"
                }
            ]

            return output
        },
        logicSourceItems: function(){
            let output = [
                {
                    text: "questionMap",
                    value: "questionMap"
                },
                {
                    text: "otherLogic",
                    value: "otherLogic"
                },
                {
                    text: "customFunction",
                    value: "customFunction",
                    disabled: true
                }
            ]

            return output
        },
        inputSourceItems_questions: function(){
            let questions = this.questions
            let language = this.language
            let output = []

            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                output.push({
                    text: "#"+(i+1)+" | ID:"+question.id+" - "+question.body.text[language],
                    value: question.id
                })
            }

            return output
        },
        questionResources: function(){
            function returnArray(array, key, collection){
                let output = []

                for(let i=0; i<array.length; i++){
                    let record = array[i]
                    output.push(record[key])
                    if(collection){
                        collection.push(record[key])
                    }
                }

                return output
            }

            let output = {}
            let questionMap = this.questionMap

            for(let qid in questionMap){
                let question = questionMap[qid]
                output[qid] = {
                    displayLogic: null,
                    prompts: question.prompts ? returnArray(question.prompts, 'prompt_id') : [],
                    logics: [],
                    group: {}
                }
                output[qid].displayLogic = question.displayLogic ? returnArray(question.displayLogic.rules, 'rule_id', output[qid].logics) : null
                
                for(let g=0; g<question.groups.length; g++){
                    let group = question.groups[g]
                    output[qid].group[group.id] = {
                        displayLogic: group.displayLogic ? returnArray(group.displayLogic.rules, 'rule_id', output[qid].logics) : null,
                        choice: {}
                    }

                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        output[qid].group[group.id].choice[choice.id] = {
                            displayLogic: choice.displayLogic ? returnArray(choice.displayLogic.rules, 'rule_id', output[qid].logics) : null,
                        }

                    }
                }
            }

            return output
        },
        logicResources: function(){
            let logics = this.logics
            let output = {}

            for(let i=0; i<logics.length; i++){
                let logic = logics[i]
                output[logic.id] = {
                    questions: {}
                }
                
                for(let r=0; r<logic.rules.length; r++){
                    let rule = logic.rules[r]
                    
                    if(rule.source.type=='questionMap'){
                        if(!output[logic.id].questions[rule.source.input.question_id]){
                            output[logic.id].questions[rule.source.input.question_id] = {
                                groups: {}
                            }                            
                        }

                        output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id] = null

                         if(rule.source.input.choice_id===null && typeof rule.value=='object'){
                            if(!output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id]){
                                output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id] = {choices: []}
                            }
                            let choiceMap = this.choiceMap(this.questionMap[rule.source.input.question_id],rule.source.input.group_id)
                            let valueMap = {}
                            for(let c=0; c<choiceMap.length; c++){
                                let choice = choiceMap[c]
                                valueMap[choice.value] = choice
                            }
                            
                            for(let v=0; v<rule.value.length; v++){
                                let value = rule.value[v]
                                output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id].choices.push(valueMap[value].id)
                            }

                         }
                        

                        // if(rule.source.input.choice_id!=null){
                        //     if(!output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id]){
                        //         output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id] = {choices: []}
                        //     }
                        //     output[logic.id].questions[rule.source.input.question_id].groups[rule.source.input.group_id].choices.push(rule.source.input.choice_id)
                        // }
                    }
                }
            }

            return output
        },
        promptResources: function(){
            let prompts = this.prompts
            let output = {}
            for(let p=0; p<prompts.length; p++){
                let prompt = prompts[p]
                output[prompt.id] = {

                }

                for(let r=0; r<prompt.references.length; r++){
                    let referenceID = prompt.references[r]
                }
            }

            return output
        },
        mockLogicIndex: function(){
            let output = {
                1: {
                    requirement: 'allTrue',
                    description: 'Has bone mets',
                    rules: [
                        {
                        source: {
                            type: 'questionMap',
                            input: {
                                question_id: 1,
                                group_id: 1,
                                choice_id: 3,
                            }
                        },
                        value: ['33'],
                        operator:'equals'
                        }
                    ]
                },
                2: {
                    requirement: "allTrue",
                    description: 'No bone mets and Recurrent mCSPC',
                    rules: [
                        {
                        source: {
                            type: 'questionMap',
                            input: {
                            question_id: 3,
                            group_id: 1,
                            }
                        },
                        value: ['none'],
                        operator:'equals'
                        },
                        {
                        source: {
                            type: 'questionMap',
                            input: {
                            question_id: 6,
                            group_id:1,
                            }
                        },
                        value:'recurrent',
                        operator:'equals'
                        }
                    ]
                }
            }

            return output
        }
    },
    watch: {
        isPreview: function(){
            if(this.isPreview){
                this.backup()
                this.displayHandler()
            }else{
                this.restore()
                // let restore = JSON.parse(this.undo)
                // this.questions = restore.questions
                // this.hub = restore.hub
                // this.prompts = restore.prompts
                // this.init()
            }
        }
    }
}
</script>

<style scoped>
.debug-logic-summary .header{
  border-bottom: 1px solid darkgrey;
}
.debug-logic-summary .striped:nth-child(odd){
  background-color: #dddddd;
}

.debug-logic-summary .true{
  font-weight: bold;
  color: green;
}

.debug-logic-summary .false{
  font-weight: bold;
  color: red;
}

.prompt{
  /* border: 1px solid teal; */
  /* padding: 12px; */
  /* border-radius: 5px; */
  /* margin: 15px 0px 15px 0px; */
}

.nav-card-prompt{
    border: 1px solid teal;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 15px;
}
    .nav-card-prompt:hover{
        background-color: lightgrey  
    }

.question-container{
    position: relative;
    border: 1px solid lightgrey;
    margin: 15px;
}

.hidden{
    background-color: lightgrey;
    border: 1px solid darkgrey;
    color: grey;
}

.hasLogic{
  border: 1px solid purple;
}

.highlight{
  border-color: red;
}

.reference{
    font-size: 0.8em;    
}
</style>